<template>
    <div class="learning">
      <div class="center-title">我的学习记录</div>
      <ul class="learn-list">
        <li class="learn-info" v-for="item in studyRecord" :key="item.id">
          <!-- <router-link :to="'/recordedInfo/'+item.id" class="learn-title"> -->
            <img class="learn-img" :src="item.image" alt="">
            <div class="learn-content">
              <div class="learn-title">{{item.name}}</div>
              <div class="str-tim">开始时间  {{item.start_date}}</div>
              <div class="end-tim">结束时间  {{item.end_date}}</div>
            </div>
            <div class="progress">
              <JinDu :percentNum="item.progress" :speed="'3'"/>
            </div>
          <!-- </router-link> -->
        </li>
      </ul>
    </div>
  </template>
  <script>
  import JinDu from '@/components/pc/jindu.vue'
  export default {
    name: 'learning',
    data(){
      return {
        studyRecord: [],  //记录
      }
    },
    components: {
      JinDu
    },
    mounted(){
      this.getStudyRecord(); //获取记录
    },
    methods: {
      //获取记录
      getStudyRecord(){
        this.$axios.post(`/v1/personal/studyRecord`, {}, {useLog: true}).then(res => {
          if(res.code==0){
            this.studyRecord = res.data;
          }
        })
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .user-img{
    display: block;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    float: none;
    margin-bottom: 20px;
  }
  .form-box{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .form-title{
      font-size: 16px;
      color: #666;
    }
    .form-text{
      box-sizing: border-box;
      width: 290px;
      padding: 0 14px;
      background: #FFF;
      border-radius: 6px;
      border: 1px solid #E5E5E5;
      margin-left: 20px;
    }
    .form-input{
      width: 100%;
      padding: 8px 0 9px;
      font-size: 14px;
      color: #333;
    }
  }
  .sub-btn{
    width: 114px;
    height: 40px;
    line-height: 40px;
    background: #254ED4;
    border-radius: 6px;
    font-size: 16px;
    color: #FFF;
    cursor: pointer;
  }
  .learn-info{
    overflow: hidden;
    margin-bottom: 24px;
  }
  .learn-img{
    width: 145px;
    height: 80px;
    border-radius: 6px;
    float: left;
  }
  .learn-content{
    width: 380px;
    float: left;
    margin-left: 10px;
  }
  .learn-title{
    font-size: 16px;
    color: #333;
    padding-top: 2px;
    margin-bottom: 14px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .str-tim{
    margin-top: 14px;
    margin-bottom: 6px;
  }
  .str-tim,
  .end-tim{
    font-size: 12px;
    color: #999;
    line-height: 16px;
  }
  .progress{
    width: 54px;
    height: 54px;
    float: right;
    margin-top: 13px;
  }
  ::v-deep .percentloop{
    .number{
      font-size: 12px;
    }
  }
  </style>
  